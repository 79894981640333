
.italic-placeholder::placeholder {
  font-style: italic;

  /* body {
    /* background-color: #222529 !important; */
    /* background-color: #131313 !important; */
    /* background-color: black !important; */
  
  /* } */ 

}

body {
  /* background-color: #0d1d25 !important; */
  /* background-color: #3f3f46 !important; */
  background-color: #18181b !important;
    font-family: 'Roboto', sans-serif;
}

.left-italic {
  transform: skewX(7deg);
  display: inline-block;
}


.select-centered {
  text-align: center !important;
  -webkit-text-align-last: center !important;
  -moz-text-align-last: center !important;
  -ms-text-align-last: center !important;
  -o-text-align-last: center !important;
  text-align-last: center !important;
}